// 
// profile.scss
//

.post-user-comment-box {
    background-color: $gray-100;
    margin: 0 -.75rem;
    padding: 1rem;
    margin-top: 20px;
}

$min-page-height: calc(100vh - #{$topbar-height});

.user-profile {
	padding-bottom: 0;
	.navbar-custom {
		justify-content: center;
		left: 0;
		&:before {
			content: none;
		}
		.logo-box {
			display: block !important;
		}
		svg {
			width: 180px;
			path {
				fill: $white;
			}
		}
	}

	.content-page {
		margin-left: 0 !important;
		padding: 0;
		min-height: $min-page-height;

		.row {
			> div {
				min-height: $min-page-height;
			}
		}
	}
	.user-info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: $sc-bg-light;
		padding: 60px;
		.profile-pic {
			margin-bottom: 30px;
			img {
				width: 220px;
			}
		}
		h1 {
			font-weight: 600;
			text-align: center;
		}
		h3 {
			color: $sc-mint;
			margin-top: 0;
			font-weight: 400;
		}
		.user-details {
			margin: 30px 0;
			padding-left: 0;
		}
		li {
			display: flex;
			list-style-type: none;
			font-size: 1.1rem;
			margin: 20px 0;
		}
		span {
			display: inline-block;
		}
		.label {
			color: $black;
			font-weight: 600;
			width: 8rem;
		}
		.qr-code {
			text-align: center;
		}
	}
	.user-detials {
		text-align: center;
	}
	.user-profile-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		padding: 4rem 5vw;
	}
	.user-status {
		margin-bottom: 50px;
		padding-left: 40px;
		background-repeat: no-repeat;
		background-position: left center;
		&.status-valid {
			color: $sc-mint;
			background-image: url(../images/icons/valid-large.svg);
		}
		&.status-invalid {
			color: $sc-invalid;
			background-image: url(../images/icons/invalid-large.svg);
		}
	}
}